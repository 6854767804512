:root{
  --lightBlue: #fd5c08;
  --mediumBlue: #00719c;
  --darkBlue: #00415a;
  --blackBlue: #001f2b;
  --gold: #feb800;
  --orangeCream: #f5804e;
  --orange: #fd5c08;
  --gray: #535353;
  --darkGray: #3f3e41;
  --lightGray: #f1f1f1;
}

body{
  background-color: white;
  font-family: 'Raleway', sans-serif;
  font-weight:400;
  font-size:14px;
  overflow-x:hidden;
}

.container-small{
  width:80% !important; 
  margin-left: auto;
  margin-right: auto;
}

.container-big{
  width:98% !important; 
  margin-left: auto;
  margin-right: auto;
}

.text-orange{
  color: var(--orange);
}

.text-gray{
  color: var(--gray);
}

.text-darkGray{
  color: var(--darkGray);
}

.bg-orange{
  background-color: var(--orange);
}

.bg-gray{
  background-color: var(--gray);
}

.bg-darkGray{
  background-color: var(--darkGray);
}

.bg-lightGray{
  background-color: var(--lightGray);
}

.btn-lightGray{
  background-color: var(--lightGray);
}

.btn-lightGray:hover{
  background-color: var(--orange);
  color: white;
  transition: .3s ease;
}

.border-radius-0{
  border-radius: 0;
}

a{
  text-decoration: none !important;
}

.mainMenu .active{
  background-color: var(--orange);
}

.active .nav-link{
  color: white !important;
}


.nav-item.active span{
  color: white;
}

.navlink-item:hover:not(.active){
  background-color: var(--lightGray);
  transition: .3s ease;
  color: rgba(0,0,0,.55);
}

.text-lightBlue{
  color: var(--lightBlue) !important;
}

.text-darkBlue{
  color: var(--darkBlue) !important;
}

.btn-footer-links{
  background-color: var(--darkGray);
  color: white;
}

.btn-footer-links:hover{
  background-color: var(--gray);
  transition: .3s ease;
  color: white !important;
}

.btn-primary{
  background-color: var(--lightBlue) !important;
  border: 0 !important;
}

.btn-primary:hover{
  background-color: var(--darkGray) !important;
}

.btn-gray{
  background-color: var(--lightGray);
}

.btn-gray:hover{
  transition: .3s ease;
  background-color: var(--darkGray);
  color: white;
}

.btn-darkGray{
  background-color: var(--darkGray);
}

.btn-darkGray:hover{
  transition: .3s ease;
  background-color: var(--orange);
  color: white;
}

#sidebar-menu{
  cursor: pointer;
}

.width-16px{
  width: 16px !important;
}
.opacity-0{
  opacity:0;
  transition: all 0.4s;
}

.opacity-100{
  opacity: 100;
  transition: all 0.4s;
}

.bg-darkblue{
  background-color: #201d1e;
}

.bg-lightblue{
  background-color: var(--lightBlue);
}

.bg-primary{
  background-color: var(--lightBlue) !important;
}

.fs-8{
  font-size:0.75rem;
}

.fs-9{
  font-size:0.6rem;
}

.fs-7{
  font-size:1.10rem;
}

.fs-16{
  font-size: 16px !important;
}

.fs-20{
  font-size: 20px !important;
}

.fs-34{
  font-size: 34px !important;
}

.fs-50{
  font-size: 50px !important;
}

.fs-5rem{
  font-size: 5rem !important;
}

.fs-2rem{
  font-size: 2rem !important;
}

.fs-150rem{
  font-size: 1.5rem !important;
}

.fs-4rem{
  font-size: 4rem !important;
}

.fs-3rem{
  font-size: 3rem !important;
}

.fw-600{
  font-weight: 600 !important;
}

.fw-500{
  font-weight: 500 !important;
}

.fw-800{
  font-weight: 800 !important;
}

.fw-700{
  font-weight: 700 !important;
}

.fw-900{
  font-weight: 900 !important;
}

.lh-1{
  line-height: 1 !important;
}

.lh-125{
  line-height: 1.25 !important;
}

.lh-150{
  line-height: 1.50 !important;
}

.mw-inherit{
  max-width: inherit !important;
}

.text-shadow-1{
  text-shadow: 1px 1px 0px #000;
}

.text-justify{
  text-align: justify;
}

.w-30{
  width: 30%;
}

.w-60{
  width: 60%;
}

.w-40{
  width: 40%;
}
.fs-sm{
  font-size: smaller !important;
}

.pt-0{
  padding-top: 0 !important;
}

.pb-0{
  padding-bottom: 0 !important;
}

.py-0{
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.px-0{
  padding-left:0 !important;
  padding-right:0 !important;
}
.img-80{
  width: 80px;
}
.w-40px{
  width: 40px;
}

.w-75{
  width: 75% !important;
}

.w-35px{
  width: 35px;
}

.me-15px{
  margin-right: 15px !important;
}

.h-40px{
  height: 40px;
}

.h-100{
  height: 100%;
}

.icon-size-30{
  height: 30px;
  width: 30px;
}

.icon-size-60{
  height: 60px;
  width: 60px;
}

.navbar, .navbar-brand{
  padding: 0 !important;
}

.navbar.center .navbar-inner {
  text-align: center;
}

.navbar.center .navbar-inner .nav {
  margin:0 auto;
  float: none;
  vertical-align: middle;
}

.mask{
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-attachment: fixed;
  z-index:790;
  height: auto;
}

.carousel-item{
  background-position: center !important;
}

.carousel-mask{
  /* background-color: rgba(0, 0, 0, 0.10); */
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-attachment: fixed;
  z-index:790;
  height: auto;
}

.carousel-main-text{
  font-size: 7.5vw;
}

.main-carousel-size{
  height: 85vh;
}

.box-item.style1{
  background-color: #f9f9f9;
  padding: 30px;
  border-bottom: 2px solid #ededed;
}

.box-icon{
  border: 1px solid var(--lightBlue);
  border-radius: 50%;
  display: inline-block;
  text-align: center;
  line-height: 65px;
  color: var(--orange);
  font-size: 30px;
  margin-bottom: 20px;
  transition: .5s;
  -webkit-transition: .5s;
  -moz-transition: .5s;
  -ms-transition: .5s;
  -o-transition: .5s; 
}

.box-item:hover .box-content i {
  background-color: var(--lightBlue);
  color: #fff;
}
.carousel{
  z-index: 700 !important;
}

.sidenav{
  position:fixed;
  width: 300px;
  float:right;
  height: 100vh;
  top: 0;
  right: -100%;
  background: #fff;
  color: #000;
  transition: all .7s ease;
  /* box-shadow: -7px 3px 3px rgba(51, 51, 51, 0.5); */
  text-align: right;
  padding: 30px 30px;
  z-index:800;
}

.sidenav ul li a{
  color: #201d1e;
  display:block;
  text-decoration: none;
  padding: 10px 15px;
}
.sidenav ul li a:hover, .sidenav ul li a.active{
  background: #f9f9f9;
}
.sidenav.active {
  right: 0;
  transition: all .7s ease;
}

.sidenav .title{
  border-bottom: 1px solid #ddd;
  text-align: left;
}

.close-icon:hover .box-icon{
  background-color: var(--lightBlue);
  color: #fff;
  cursor: pointer;
}

.log-in-button{
  cursor:pointer;
}

.log-in-form{
  position:fixed;
  width: 300px;
  float:right;
  height: 300px;
  top: 50%;
  right: 50%;
  background: #fff;
  color: #000;
  transition: all .7s ease;
  /* box-shadow: -7px 3px 3px rgba(51, 51, 51, 0.5); */
  text-align: right;
  padding: 30px 30px;
  z-index:800;
}

.accordion-button{
  text-align: right !important;
  padding: 0 !important;
  font-size: 1.25rem !important;
  justify-content: flex-end;
}

.accordion-button:hover{
  background-color: #f9f9f9;
}

.accordion-button:focus{
  border-color: inherit !important;
  box-shadow: inherit !important;
}

.accordion-button:not(.collapsed){
  color: inherit !important;
  background-color: #f9f9f9;
  box-shadow: inherit !important;
}

.accordion-button::after{
  margin-left:0.255rem !important;
  display:none;
}

.accordion-button:not(.collapsed) .dropdown-toggle::after{
  transform: rotate(-180deg);
}

.dropdown-toggle::after{
  transform: .2s ease-in-out;
}

.sidebar-dropdown-p{
  padding: 10px 15px;
}

.accordion-body {
  padding: 0px !important;
  font-size: 1rem !important;
}

.our-trips-box{
  margin: 0 0 30px;
  padding: 0;
  transition: .3s;
}

.btn:focus{
  box-shadow: none !important;
}

.btn:hover{
  background-color: var(--darkGray);
  color: white;
  transition: .3s ease;
}

.btn.v2{
  background-color: transparent !important;
  border: 1px solid #918f90 !important;
  transition: .4s !important;
  color: #201d1e !important; 
  padding: 10px 30px !important;
}

.btn.v2:hover{
  color: #fff !important;
  background-color: var(--lightBlue) !important;
}

.breadcrumb-background{
  background-image:url("./images/pattern.jpg");
  background-blend-mode: multiply;
}

.box-our-trips{
  border-radius: 22px;
  margin: 0 0 30px;
  border: 1px solid #ddd;
}

.info-div{
  position: fixed;
  bottom: -70%;
  transition: .2s linear;
}

.info-div:after {
	bottom: 100%;
	left: 12%;
	border: solid transparent;
	content: "";
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none;
	border-color: rgba(136, 183, 213, 0);
	border-bottom-color: #ffffffde;
	border-width: 12px;
	margin-left: 0px;
}

.our-trips-image:hover .info-div{
  bottom: 0;
  transition: .2s linear;
}

.our-trips-image{
  overflow:hidden;
}

.our-trips-box{
  margin: 0 !important;
}

.bg-white-90{
  background-color: #ffffffde;
}

.home-box-images{
  height: 350px !important;
  background-position: 50% !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
}
.box-our-trips-img{
  border-radius: 20px 0 0 20px;
  cursor: pointer;
  background-position: 50% !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-color: #ddd;
}
.box-our-trips h4{
  font-weight: 600;
  margin: 0;
  font-size: 22px;
}

.box-our-trips-info{
  padding: 20px 25px;
}

.box-our-trips-info-meta{
  display: flex;
  align-items:center;
  line-height: 1;
  margin: 12px 0;
  flex-direction: column;
  align-items: flex-start;
  align-content: flex-start;
}

.box-our-trips p{
  margin: 0 0 12px;
  font-size: 15px;
  line-height: 26px;
}

.box-our-trips-info-price span{
  font-weight: 600;
  color: #000;
  font-size: 16px;
}

.box-our-trips-info-meta-days{
  color: #201d1e;
  font-weight: 400;
  font-size:16px;
}

.box-our-trips-info-options{
  display:flex;
  flex-wrap: wrap;
  line-height: 1;
  margin: 15px 0 0 ;
  border-top: 1px solid #ddd;
  padding-top:15px;
  justify-content: flex-end;
}

.box-our-trips-info-options-link{
  font-size: 15px;
  line-height: 26px;
  font-weight: 500;
  transition: .3s;
}

.box-our-trips-info-options a{
  color: #000;
  text-decoration: none;
}
.hoverSize{
  transition: .3s;
}

.hoverTransition3{
  transition: .3s;
}

.hoverSize:hover{
  font-size: larger !important;
  color: var(--lightBlue) !important;
}
.box-our-trips-info-options a:hover, .hoverLetterSpacing:hover{
  color: var(--lightBlue) !important;
  letter-spacing: .5px;
  cursor: pointer;
}

.css-11mde6h-MuiPaper-root{
  box-shadow: none !important;
  border: 1px solid #ddd !important;
  padding: 20px;
}

.css-1w1rijm-MuiButtonBase-root-MuiButton-root{
  padding: 0 !important;
  min-width: inherit !important;
}

.cursor-pointer{
  cursor: pointer;
}

.contact-box{
  text-align:center;
  background: white;
  border: 1px solid #ddd;
  border-radius: 20px 20px ;
  margin: 10px 10px 40px;
  padding: 30px 10px;
}

.contact-box-info p{
  line-height: 10px;
  color: #626061;
}

.contact-box-info h5{
  font-weight: 700;
  color: #201d1e;
  line-height: 1.4;
  font-size: 1.25rem;
  margin-bottom: 20px;
}

.contact-box-icon{
  font-size: 30px;
  border: 1px solid #ddd;
  border-radius: 50%;
  padding: 10px;
  width: 60px;
  height: 60px;
  transition: all 0.5s ease;
  line-height: 65px;
  margin: 0 auto;
}

.form-group{
  margin: 0 0 25px;
}

.form-group input{
  height: 60px;
}

.form-group textarea {
  height: 140px;
  resize: none;
  padding-top: 10px;
  padding-bottom: 10px;
}

.contact-form {
  background-color: #fafafa;
  padding: 50px;
}

.btn-contact{
  width: 100%;
  font-size: 18px;
  border: 1px solid transparent;
  padding: 16px 15px;
  display:block;
  font-weight: 500;
}

.contact-form .section-title h2{
  font-size: 35px;
  font-weight: 700;
  line-height: 1.4;
  color: #201d1e;
}

.contact-form .section-title span{
  color: var(--lightBlue);
  line-height: 1.5;
  display: inline-block;
  font-weight:500;
}

.subrow-first{
  width:100px;
}

.table-print{
  display:none !important;
}

#Grilla .container{
  max-width: 100% !important
}
.MuiButton-root{
  padding: 0 !important;
}

#tableToPdf .MuiToolbar-root,#tableToPrint .MuiToolbar-root{
  display: none !important;
}

#tableToPrint button{
  min-width: inherit !important;
}

.add-passenger-input-name .dropdown-menu{
  max-height: 250px;
  overflow-y: auto;
  width:98%;
  top: 55px;
  left: 4px;
}

.disable-select{
  user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

.newsletter input::placeholder{
  color: white;
  text-align:center
}

#socialMedia span{
  position: relative;
}

.footer{
  z-index:-999;
}

#socialMedia span::before{
  content: "";
  position: absolute;
  height: 95%;
  width: 99%;
  background-color: #fff;
  top: 62px;
  right: 4px;
  clip-path: polygon(100% 0, 0 0, 100% 14%);
}

.carousel-text::first-letter{
  text-transform: uppercase;
}

#trip-info .transport{
  position:relative;
}

#trip-info .transport:after{
  top: 100%;
	left: 0;
	border: solid transparent;
	content: "";
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none;
	border-color: rgba(136, 183, 213, 0);
	border-top-color: var(--orange);
	border-width: 11px 43px;
	margin-left: 0px;
}

.home-destinos-title{
  font-family: 'Kaushan Script', cursive;
}

.outstanding-trip{
  background-blend-mode: multiply;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
}

.outstanding-trip-blend{
  background: linear-gradient(0deg, rgb(211, 148, 73, 0.2) 0%, rgb(157, 31, 61, 0.2) 100%);
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-blend-mode: hard-light;
}

.position-relative{
  position:relative;
}

.img-effect{
  border: 5px solid transparent;
  box-sizing: border-box;
  position: relative;
  cursor: pointer;
}

.img-effect:before{
  transition: all .5s ease;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #333;
  transform: scale(0);
}

.img-effect:hover:before {
  opacity: .5;
  transform: scale(1);
  }

.img-effect:after {
  transition: all .6s ease .2s;
  content: "";
  position: absolute;
  top: 8px;
  left: 8px;
  right: 8px;
  bottom: 8px;
  border: 1px solid #aaa;
  background: #000;
  opacity: 0;
  transform: scale(0);
  }

.img-effect:hover:after {
  opacity: .35;
  transform: scale(1);
}

.btn-wsp{
  position: fixed;
	width:60px;
	height:60px;
	bottom:40px;
	right:40px;
	background-color:#25d366;
	color:#FFF;
  border: none;
	border-radius:50px;
	text-align:center;
  font-size:30px;
	box-shadow: 2px 2px 3px #999;
  z-index: 999;
}

.btn-wsp:hover{
  background-color: #4cfa8c;
  transition: .3s ease;
}

.btn-wsp .tooltiptext {
  visibility: hidden;
  width: 180px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 7px 0;
  position: absolute;
  z-index: 998;
  top: 12px;
  right: 105%;
  font-size: 14px;
}

.btn-wsp:hover .tooltiptext {
  visibility: visible;
}

#preloader {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-content: flex-start;
  align-items: center;
  flex-direction: column;
}
#loader {
  position: relative;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: #fd9308;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}
#loader:before {
  content: "";
  position: absolute;
  top: 5px;
  left: 5px;
  right: 5px;
  bottom: 5px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: var(--orangeCream);
  -webkit-animation: spin 3s linear infinite;
  animation: spin 3s linear infinite;
}
#loader:after {
  content: "";
  position: absolute;
  top: 15px;
  left: 15px;
  right: 15px;
  bottom: 15px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: var(--orange);
  -webkit-animation: spin 1.5s linear infinite;
  animation: spin 1.5s linear infinite;
}

@-webkit-keyframes spin {
  0%   {
      -webkit-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
      transform: rotate(0deg);
  }
  100% {
      -webkit-transform: rotate(360deg);
      -ms-transform: rotate(360deg);
      transform: rotate(360deg);
  }
}
@keyframes spin {
  0%   {
      -webkit-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
      transform: rotate(0deg);
  }
  100% {
      -webkit-transform: rotate(360deg);
      -ms-transform: rotate(360deg);
      transform: rotate(360deg);
  }
}

@media print {
  .table-print{
    display: flex !important;
    overflow: hidden !important;
  }
  .MuiToolbar-root{
    display:none !important;
  }
  #noSeatsPassengers{
    display:none !important;
  }
  .hideOnPrint{
    display: none !important;
  }
  .fw-600-onPrint{
    font-weight: 600; 
  }
  .scale-072-onPrint{
    transform: scale(0.72);
  }
  .display-block-onPrint{
    display:block !important;
  }
  .display-flex-onPrint{
    display:flex !important;
  }
  .justify-content-center-onPrint{
    justify-content: center !important;
  }
  .m-1-onPrint{
    margin: 0.25rem !important;
  }

}

@media (max-width: 779px){
  .fs-50{
    font-size: 41px !important;
  }
  .sm-text-center{
    text-align: center !important;
  }
  .our-trips-image .info-div {
    bottom: 0 !important;
    transition: .2s linear;
  }
  .our-trips-box{
    margin-bottom:10px !important;
  }
  .fs-5rem{
    font-size: 2.5rem !important;
  }
  .fs-34{
    font-size: 22px !important;
  }
  .newsletter {
    height: 293px !important;
    background-size: cover !important;
  }
  .sm-flex-column{
    flex-direction: column !important;
  }
  .fs-3rem{
    font-size: 2rem !important;
  }
  .fs-7{
    font-size: 0.9rem !important;
  }
  .w-60{
    width: 100% !important;
  }
  .fs-sm-08rem{
    font-size: 0.8rem !important;
  }
  .sm-align-items-center{
    align-items: center !important;
  }
  #lower-right{
    display: none !important;
  }
  .outstanding-trip{
    padding: 3% !important;
  }
  .outstanding-trip .p-5{
    padding: 0 !important;
  }
  .outstanding-trip .w-75{
    width: 100% !important;
  }
  #PrimaryBar{
    margin-top: 10px;
    margin-bottom: 10px;
  }
  #PrimaryBar .navbar{
    justify-content: center !important;
    align-items: center !important;
    width: 100% !important;
    flex-direction: row !important;
  }

  #PrimaryBar .navbar .collapse {
    height: inherit !important;
  }

  #PrimaryBar .mx-3{
    margin-right: 0.3rem !important;
    margin-left: 0.3rem !important;
  }

  #PrimaryBar .mainMenu {
    height: inherit !important;
    margin-top: 10px !important;
    flex-direction: row !important;
    justify-content: center !important;
  }
  #PrimaryBar .py-3, #PrimaryBar .px-4{
    padding: 0rem !important;
  }
  #PrimaryBar{
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
  #PrimaryBar .navbar-collapse{
    display: block !important;
  }
  #PrimaryBar .navbar-toggler{
    display: none !important;
  }
  #header-right {
    width: 100% !important;
  }
  .sm-w100{
    width: 100% !important;
  }
  .sm-w50{
    width: 50% !important;
  }
  .sm-justify-content-center{
    justify-content: center !important;
  }
  .sm-p8{
    padding: 8px !important;
  }
  .main-carousel-size {
    height: 95vh !important;
  }
  .carousel-text{
    margin-bottom: 15px !important;
  }
  #paxInfo .col-6{
    width: 80% !important;
  }
}